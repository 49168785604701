<template>
  <div class="hidden lg:flex  max-w-[180px] w-[180px] lg:flex-col lg:fixed lg:inset-y-0">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex flex-col flex-grow bg-cyan-700 pt-2 pb-0 overflow-y-auto">
      <div class="flex items-center justify-center mt-2">
        <a href="https://www.oxytek.cl/" target="_blank">
          <img width="110" class="w-110 mx-auto" src="../../assets/logo_blanco.png" />
        </a>
      </div>
      <nav class="mt-8 ml-0 p-0">
        <div  :class="['cursor-pointer pl-0 font-medium    px-0 space-y-1',
                 this.seleccion.resumen  == true ? 'bg-cyan-600 text-white' : 'bg-cyan-700 text-cyan-100']">
          <a @click="ExitCodAccion"
            class="cursor-pointer text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
            </svg>
            <span class="ml-2"> Resumen</span>
          </a>
        </div>
      </nav>
      <nav class="p-0  mt-2 mb-0  divide-y divide-cyan-800 overflow-y-auto" @dragover="handleDragOVer"
        @dragenter="handleDragEnter" @drop="handleDrop">
        <div v-for="f in this.$store.getters.getDroppables" :key="f.idFaena" class="">
          <div v-if="f.visible == 1" class="flex flex-col items-start  mt-1 py-3" :draggable="true"
            @drop="handleDrop(f.id)" @dragstart="handleDragFaena(f.id)">
            <div class="flex items-center">
              <!-- Contenedor del Icono -->
              <div class="icono flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="white" class="w-8 h-8">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h.01M15 12h.01M9 6h.01M15 6h.01M9 18h.01M15 18h.01" />
                </svg>
              </div>

              <!-- Contenedor del Centro -->
              <div  class="text-sm flex-grow ml-2 text-yellow-200 font-bold" >
                Centro {{ f.nombre }}
              </div>
            </div>
            <div class="flex w-full ">
              
              <div class="col2 w-1/2 text-center flex items-center justify-center">
              
                <span @click="loadHistorial(f)" 
                 :class="['cursor-pointer ml-3  px-5 py-3 text-sm leading-6 font-medium    hover:text-white hover:bg-cyan-600',
                 this.seleccion.historial  == f.id ? 'bg-yellow-500 text-white' : 'bg-cyan-700 text-cyan-100']" >
                  Historial
                </span>
              </div>
              <div class="col1 w-1/2 text-center flex items-center justify-center">
                <span @click="loadCentros(f)"    :class="['cursor-pointer  px-5 py-3 text-sm leading-6 font-medium    hover:text-white hover:bg-cyan-600',
                 this.seleccion.live  == f.id ? 'bg-yellow-500 text-white' : 'bg-cyan-700 text-cyan-100']" >
                 Live
                </span>
              </div>
            </div>
          </div>
        </div>


      </nav>

      <nav class="mt-0 flex-1 flex flex-col divide-y divide-cyan-800  overflow-auto">
        <div class="px-2 space-y-1">
          <div v-for="f in this.$store.getters.getDraggables" :key="f.id" class="mt-1 pt-1x">
            <div class="flex items-center">
              <div class="w-full text-left  bg-cyan-800">
                <span :draggable="true" @drag="handleDrag(f.id)" @dragend="handleDragEnd"
                  class="cursor-pointer group flex items-center px-2 py-1 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600">
                  {{ f.nombre }}
                </span>
              </div>

            </div>
          </div>
        </div>
      </nav>
      <nav class="flex-1 flex flex-col divide-y divide-cyan-800   pl-2  m-0">

        
    <a 
      v-for="item in adminNavigation" 
      :key="item.name" 
      :href="item.href" 
      @click.prevent="item.click ? item.click() : null"
      style="cursor: pointer;"
      class="group flex items-center px-2 py-1 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
    >
      <component :is="item.icon" class="mr-1 h-6 w-6 text-cyan-200" aria-hidden="true" />
      {{ item.name }}
    </a>
   
 
        <a v-for="item in secondaryNavigation" :key="item.name" :href="item.href"
          class="group flex items-center mt-2 px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600">
          <component :is="item.icon" class=" mr-1 h-6 w-6 text-cyan-200" aria-hidden="true" />
          {{ item.name }}
        </a>


      </nav>

    </div>
  </div>

</template>
<script>
import axios from "axios";
import { HomeIcon } from "@heroicons/vue/outline";

import { mapActions } from "vuex";
import {
  //BellIcon,

  LibraryIcon,
  UserGroupIcon,

  MapIcon
} from "@heroicons/vue/outline";
export default {
  name: "SideBarDesktopCliente",
  emits: [
     "chgFaenaCentros",
     "chgIdPantalla",
     "chgIdPantalla2",
     "handleDropFaena",
     "loadModalUsuarios",
    "loadModalServiceUnavailable"],
  data(){
   return{
    seleccion:{
      historial:0,
      live:0,
      resumen:false
    },
    adminNavigation: [
        { name: "Usuarios",  click: () => this.showModalUsuario(), icon: UserGroupIcon },
         
      ]
    }
  },
  props: {
    navigation: {
      type: Object,
    },
    secondaryNavigation: {
      type: Object,
    },
     
    faenas: {
      type: Array,
    },
  },
  watch: {
    faenas: function (f) {
      let obj = [];
      let pos = 0;
      f.forEach((objeto) => {
        pos++;
        obj.push({
          id: objeto.id,
          nombre: objeto.nombre,
          tipo: objeto.tipo,
          consumo_o2: objeto.consumo_o2,
          pos: pos
        });
      });
      // descomentar 
      //  this.$store.dispatch("draggableCreate", obj);
    },
  },
  components() {
    HomeIcon,
    LibraryIcon,
  UserGroupIcon,

  MapIcon
  },
  methods: {
    ...mapActions("draggdropp", ["draggableCreate", " setIdCurrentDrag"]),
   async  showModalUsuario(){
      let cod = await this.chkPermisos();
      console.log( 'cod', cod );
      if (cod == 401){
        this.$emit("loadModalErrorAcceso", true);
      }else{
        if (cod == 200){
          this.$emit("loadModalUsuarios", true);
        }else{
         
          this.$emit("loadModalServiceUnavailable", true);
        }  
      }   
    },
    async chkPermisos() {
      try {
        const apiKey = this.$store.state.login.tokenLogin;

        const data = {};
        const url = "/api/admin/getUsers";
        const resp = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${apiKey}`,
          },
        });
        if (resp.status === 200) 
        {
         
          
            return  resp.data.status; 
             
         
        }
        return 0;

      } catch (err) {
        console.log(err.response) ;         
      }  
    },
    showModalConfiguracion(){
       console.log('configuracion');
    },
    loadCentros(f) {
      this.seleccion.live = f.id;
      this.seleccion.historial = 0;
      this.seleccion.resumen = false;
      console.log("carga centros de Faena ", f);
      this.$emit("chgFaenaCentros", f);
      // this.$emit("chgCentros", f);
    },
    loadHistorial(f) {
      console.log("historial ", f.id);
      this.seleccion.historial = f.id;
      this.seleccion.live = 0;
      this.seleccion.resumen = false;
      this.$emit("loadHistorial", f);
    },
    ExitCodAccion() {
      // console.log("chgIdPantalla2 ", 1);
      this.seleccion.historial = 0;
      this.seleccion.live = 0;
      this.seleccion.resumen = true;
      this.$emit("chgIdPantalla", { id: 1 });
    },
    handleDragOVer(event) {
      console.log("over");
      event.preventDefault();
    },
    handleDrop(id) {
      console.log("DROP en Faena LATERAL", id);
      this.$store.dispatch("setPosCurrentDroppable", id);
      this.$emit("handleDropFaena");
    },
    handleDrag(id) {
      console.log('tomando ', id)
      this.$store.dispatch("setIdCurrentDrag", id);
    },
    handleDragFaena(id) {
      console.log("tomando faena ", id);
      this.$store.dispatch("setIdCurrentDroppable", id);
      this.isDragStart = true;
    },
    handleDragEnd() {
      console.log('fin del drag');
      this.$store.dispatch("setIdCurrentDrag", '');
    }
  },
  mounted(){
    this.seleccion.resumen = true;
  }
};
</script>
