<template>
  <div>


    <div class="md:flex md:items-center md:justify-between bg-white">
      <div class="min-w-0 flex-1 text-left pl-8">
        <h1 class="mt-3 text-1xl font-extrabold tracking-tight text-cyan-800">
          {{ nombreMedicion }} {{ nombreIdTipoMedicion }}
        </h1>
      </div>
      <div class="min-w-0 flex-1 text-left pl-0">
        <AlertDummy v-show="this.glosaDataTypeNatural !== ''" :glosa="glosaDataTypeNatural"></AlertDummy>
      </div>
      <div class="min-w-0 flex-1 text-left pl-0">
        <AlertDummy v-show="this.glosaDataTypeOxigenado !== ''" :glosa="glosaDataTypeOxigenado"></AlertDummy>
        
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        {{ labelErr }}
        <span class="isolate inline-flex rounded-md shadow-sm p-3">
          <button v-show="this.spinner == false" type="button" @click="chgTipo(1)" :class="{
            'border-gray-500 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500':
              natural,
            '': !natural,
            'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10': true,
          }">
            Natural
          </button>

          <button v-show="this.spinner == false" type="button" @click="chgTipo(2)" :class="{
            'border-blue-600 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600':
              oxigenado,
            '': !oxigenado,
            'relative -ml-px inline-flex items-center rounded-r-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 ': true,
          }">
            Oxigenado
          </button>
        </span>
        <span class="isolate inline-flex rounded-md shadow-sm ml-12 p-3">
          <button v-show="this.spinner == false" type="button" @click="loadMapa(propIdFaena)"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            Mapa
          </button>
          <button v-show="this.spinner == false" type="button" @click="showModalHelp()"
            class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            ?
          </button>
        </span>
      </div>
    </div>
    <div class="bg-white shadow-lg">

      <!--  {{ this.valorMinY0  }} - {{ this.valorMaxY0  }}-->
      <textarea style="color:red" id="adjustable-textarea" row="100" class="w-full  p-2 border border-gray-300 rounded
         text-xs focus:outline-none focus:ring-2 focus:ring-blue-500 hidden " v-model="this.fs"> </textarea>

      <textarea style="color:rebeccapurple" id="adjustable-textarea" row="100" class="w-full  p-2 border border-gray-300 rounded
         text-xs focus:outline-none focus:ring-2 focus:ring-blue-500 hidden" v-model="this.fs2"> </textarea>

      <SpinnerLoading v-show="this.spinner"></SpinnerLoading>
      <highcharts v-if="!this.mostrarSpinner" :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>
<script>
//import axios from "axios";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

//import stockInit from 'highcharts/es-modules/Stock'
import stockInit from "highcharts/modules/stock";

import SpinnerLoading from "../SpinnerLoading"
import AlertDummy from "../AlertDummy.vue";

stockInit(Highcharts);
//const colors = Highcharts.getOptions().colors;
Highcharts.setOptions({
  chart: {},
  lang: {
    months: [
      "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago",
      "Sep", "Oct", "Nov", "Dic",
    ],
    weekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  },
});

export default {
  components: {
    highcharts: Chart,
    SpinnerLoading: SpinnerLoading,
    AlertDummy:AlertDummy

  },
  emits: ["loadMapa", 
  "loadMultiplesUnidades"],
  props: [
    "seriesMediciones",
    "idMedicion",
    "nombreMedicion",
    "mostrarSpinner",
    "glosaDataTypeOxigenado",
    "glosaDataTypeNatural",
    "propIdFaena"
  ],

  data() {
    return {
    
      spinner: true,
      fs: '',
      fs2: '',
      seriesMedicionesLocal: [],
      labelErr: '',
      natural: false,
      oxigenado: false,
      idTipo: 2,
      nombreIdTipoMedicion: "",
      mediciones: [],
      showModal: false,
      Centro2: [],
      minY: 0,
      maxY: 25,
      tieneMediciones: [false, false, false],
      valorMaxY0: [0, 0, 0],
      valorMinY0: [0, 0, 0],
      chartOptions:
      {
        chart: {
          type: 'spline'
        },
        events: {
          load: function () {

          },
        },
        tooltip: {


        },
        legend: {
          enabled: true,

          layout: "vertical",
          align: "right",
          verticalAlign: "top",
        },
        plotOptions: {


          series: {
            showInNavigator: true,

          },
        },
        xAxis: {
          showLastLabel: true,
        },
        yAxis: {
          opposite: false,
          title: {
            text: 'miligramos/litro',
          },
          formatter: function (val) {
            return val.toFixed(2);
          },



        },

        series: [0],
        colors: [
          "#0C00CA",
          "#FFBF00",
          "#BF008F",
          "#5A00B4",
          "#00D563",
          "#669966",
          "#303200",
          "#A30099",
          "#CC3366",
          "#CC33FF",
          "#660000",
          "#99CCCC",
          "#CC00FF",
          "#993333",
          "#993333",
          "#993333"
        ],
        /*         series: [
           {
              "name": "Jaula 101",
              "data": [
                 [1722484800000, 7],
                 [1722571200000, 7],
                 [1722657600000, 7],
                 [1722744000000, 7],
                 [1722830400000, 7],
                 [1722916800000, 7],
                 [1723003200000, 7],
                 [1723089600000, 7],
                 [1723176000000, 7],
                 [1723262400000, 7],
                 [1723348800000, 7],
                 [1723435200000, 7],
                 [1723521600000, 7],
                 [1723608000000, 7],
                 [1723694400000, 7],
                 [1723780800000, null],
                 [1723867200000, null],
                 [1723953600000, null],
                 [1724040000000, null],
                 [1724126400000, null],
                 [1724212800000, null],
                 [1724299200000, null],
                 [1724385600000, null],
                 [1724472000000, null],
                 [1724558400000, null],
                 [1724644800000, null],
                 [1724731200000, null],
                 [1724817600000, null],
                 [1724904000000, null],
                 [1724990400000, null],
                 [1725076800000, null]
              ],
              zones: [
              { value: 1723089600000, color: "pink" },
              { value:1723262400000 , color: "magenta"},
              { color:"yellow"}
             
              ],
              "zoneAxis": "x",
              "yAxis": 0
           },
           {
              name: "Jaula 108",
              data: [
                 [1722484800000, 1],
                 [1722571200000, 1],
                 [1722657600000, 2],
                 [1722744000000, 3],
                 [1722830400000, 4],
                 [1722916800000, 5],
                 [1723003200000, 2],
                 [1723089600000, 1],
                 [1723176000000, 2],
                 [1723262400000, 4],
                 [1723348800000, 1],
                 [1723435200000, 3],
                 [1723521600000, 1],
                 [1723608000000, 7.5],
                 [1723694400000, 6.43],
                 [1723780800000, 6.59],
                 [1723867200000, 6.65],
                 [1723953600000, 6.94],
                 [1724040000000, 7.15],
                 [1724126400000, 7.27],
                 [1724212800000, 7.36],
                 [1724299200000, 7.26],
                 [1724385600000, 7.13],
                 [1724472000000, 7.36],
                 [1724558400000, 7.69],
                 [1724644800000, 7.92],
                 [1724731200000, 7.93],
                 [1724817600000, 8.25],
                 [1724904000000, 9.13],
                 [1724990400000, 8.54],
                 [1725076800000, null]
              ],
              zones: [
                 { value: 1722830400000, color: "cyan" },
                 { value: 1723089600000, color: "green" },
                 { value: 1723608000000 , color:"magenta"},
                 {  color: "green" }
              ],
              zoneAxis: "x",
              yAxis: 0
           }
        ], */

        navigator: {
          series: {
            lineWidth: 2,
            fillOpacity: 0.04,
            dataGrouping: {
              smoothed: false,
            },
          },
        },
        rangeSelector: {
          selected: 2,
          floating: true,
          y: 400,
        },
        title: {
          text: "",
        },


      },
    };
  },
  watch: {
    // seriesMediciones['',[ obj_faena_natural ],  [obj_faena_oxigenado]]->   [ { obj_faena } ] - > obj_faena.data[] -> array_datos_diarios 
    mostrarSpinner: function () {
      this.chartOptions.series = [];
      this.spinner = true;


    },

    seriesMediciones: function (valor) {

      this.seriesMedicionesLocal = valor

      if (Array.isArray(this.seriesMedicionesLocal)) {
        this.fillSeries(this.seriesMedicionesLocal);
      } else {
        console.error("seriesMediciones debe ser un arreglo. Valor proporcionado:", valor);

      }
    },
  },




  methods: {
     
    chgTipo(tipo) {



      /* let nuevoValor = this.seriesMediciones[tipo]
      nuevoValor.forEach(obj => {
    
        obj.data = obj.data.map(([timestamp, cantidad,injection]) =>
          cantidad === 0 ? [timestamp, null,injection] : [timestamp, cantidad,injection]
        );
      }); */



      if (tipo == 1) {
        this.natural = true;
        this.oxigenado = false;
        this.nombreIdTipoMedicion = "Oxigenado";
      } else {
        this.natural = false;
        this.oxigenado = true;
        this.nombreIdTipoMedicion = "Natural";
      }

      this.idTipo = tipo;
      this.nombreIdTipoMedicion = this.idTipo == "1" ? "Natural" : "Oxigenado";


      this.fillSeries(this.seriesMedicionesLocal);

    },
    fillSeries(med) {
      this.chartOptions.series = [];
      let nuevoValor = med[this.idTipo];
      //this.fs = JSON.stringify(this.seriesMedicionesLocal[this.idTipo]);

      if (nuevoValor == null) {
        this.labelErr = "no se pudieron leer datos";
        return false;
      }

      nuevoValor.forEach(obj => {

        let zones_oxigeno = []
        let ultimo_injection_tag = '';
        let index = 0;
        obj.data = obj.data.map(([timestamp, cantidad, injection]) => {


          if (index == 0) {

            ultimo_injection_tag = injection

          } else {

            if (injection !== ultimo_injection_tag) {

              zones_oxigeno.push({
                tag: injection,
                value: timestamp,
                color: ultimo_injection_tag == false && injection == true ? 'cyan' : '',
              })

            } else {
              zones_oxigeno.push({
                tag: injection,
                value: timestamp,
                color: ultimo_injection_tag == true ? 'cyan' : '',
              })


            }

            ultimo_injection_tag = injection;

          }




          cantidad = cantidad == null ? 0 : cantidad;
          if (cantidad > 0) {
            this.tieneMediciones[this.idTipo] = true;
          }



         
         this.valorMaxY0[this.idTipo] <= cantidad ? (this.valorMaxY0[this.idTipo] = cantidad) : (this.valorMinY0[this.idTipo] = cantidad);
           
          
          index++;
          return cantidad === 0 ? [timestamp, null, injection] : [timestamp, cantidad, injection];
        })

        obj.zones = zones_oxigeno;
        obj.zoneAxis = 'x';
        obj.yAxis = 0;
      });


      // this.fs2 = "<*>" + JSON.stringify(this.seriesMedicionesLocal[this.idTipo]);


      this.chartOptions.series = nuevoValor;

      this.spinner = false;
      this.$emit("loadMultiplesUnidades", true);


      if (this.tieneMediciones[this.idTipo] == false) {
        this.chartOptions.yAxis.max = 25;
        this.chartOptions.yAxis.min = 0;
      } else {
        this.fs = this.valorMinY0[this.idTipo];
        let estimadoMin = this.valorMinY0[this.idTipo] - 0.9;
        let nuevoValorMin = estimadoMin - 1 < 0 ? 0 : estimadoMin - 1;
        let nuevoValorMax = this.valorMaxY0[this.idTipo] + 0.1;
        this.chartOptions.yAxis.max = nuevoValorMax;
        this.chartOptions.yAxis.min = nuevoValorMin;

        this.chartOptions = {
          ...this.chartOptions,
          xaxis: { categories: [] },
          yaxis: [
            {
              ...this.chartOptions.yaxis,

              max: nuevoValorMax,
              min: nuevoValorMin,

            }
          ],

        };
      }

      if (this.idTipo == 2) {

        this.natural = false;
        this.oxigenado = true;
      } else {
        this.natural = true;
        this.oxigenado = false;
      }

    },


    showModalHelp() {

      this.showModal = true;
    },
    closeModalHelp() {

      this.showModal = false;
    },
    loadMapa(f) {

      this.$emit("loadMapa", f);
    },
  },

  mounted() {
    this.seriesMedicionesLocal = this.seriesMediciones;
    this.natural = false;
    this.oxigenado = true;
    this.spinner = true;
  },
};



</script>
