<template>
  <div>
    <div v-if="this.objJaulasOcultas.length > 0" class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-1">
      <div class="container mx-auto  p-3 bg-gray-200 mt-3 text-black">
        {{ msg_jaulas_ocultas }}
      </div>
    </div>


    <div v-if="dataLoaded" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-8">
      {{ error_load }}



      <div v-for="objJaula in objJaulas" :key="objJaula.id"
        class="bg-white w-100 overflow-hidden   shadow-md rounded-md mb-4 p-0">


        <div class="p-1">
          <div class="flex items-center">
            <div class="flex-shrink-0"></div>
            <div class="ml-0 flex-1 text-left">
             

                <dd class="mt-0 text-xs ">


                  <GraficoUnidadHighChart :injecta_fecha="injecta_fecha" :objJaula="objJaula"></GraficoUnidadHighChart>
                </dd>
              
            </div>
          </div>


        </div>
      </div>

    </div>
    <div class="mt-2 grid xs:grid-cols-1 sm:grid-cols-4 lg:grid-cols-4 gap-1 text-left">
      <div></div>
      <input
        class="w-100 border-1 p-4 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring "
        v-model="fecha_paso" />

      <button type="button"
        class="   w-full justify-center  rounded-md  border-gray-300 shadow-sm    border border-transparent
                   text-sm  font-medium  text-white   bg-indigo-600   hover:bg-indigo-700  focus:outline-none  focus:ring- focus:ring-offset-2    focus:ring-indigo-500  "
        v-on:click="cambiarfecha">Cambiar fecha</button>
    </div>
  </div>
</template>
<script>


import { mapGetters } from "vuex";



import GraficoUnidadHighChart from "./GraficoUnidadHighChart";

export default {
  name: "FaenasCliente",
  props: ["idEmpresa", "propIdFaena", "numRan", "propObjJaulas", "storeJaulas"],
  emits: ["chgFaena"],
  components: {

    GraficoUnidadHighChart

  },
  mounted() {

   /*  let objFecha = new Date();

    const y = objFecha.getFullYear();
    const m = String(objFecha.getMonth() + 1).padStart(2, '0'); // Los meses   son 0-11
    const d = String(objFecha.getDate()).padStart(2, '0');
 */
    this.fecha_paso = `2024-08-16`;

    this.idFaena = this.propIdFaena;
    this.dataLoaded = false
    this.getDivisionesApi();
    this.setpoint1 = 6.2;
    this.setpoint2 = 300;
    this.idEstado = 1;


    this.dataLoaded = true;
    // this.loadUnidades();
    this.objJaulas = this.filtraVisibles(this.propObjJaulas);
    console.log("objJaulas:::::", this.objJaulas)
  },
  data() {
    return {
      dataList: [],
      dataLoaded: false,
      datos_mensuales: [],
      trenes: [],
      //objJaulas: [],
      objJaulas: [],
      objJaulasOcultas: [],
      cards: [{}],
      error_load: '',
      seriesFaenas: [],
      dots: ["green", "yellow", "green", "red"],
      setpoint1: 0,
      setpoint2: 0,
      idEstado: 0,
      idFaena: this.propIdFaena,
      show: false,
      f: '',
      msg_jaulas_ocultas: '',
      fecha_paso: '',
      injecta_fecha: ''
    };
  },
  methods: {
    cambiarfecha() {
      this.injecta_fecha = this.fecha_paso;
    },
    filtraVisibles(f) {
      this.objJaulasOcultas = f.filter(item => item.visible === false);
      if (this.objJaulasOcultas.length > 0) {
        this.msg_jaulas_ocultas = "Jaulas ocultas: " + this.objJaulasOcultas.length
      }
      this.objJaulas = f.filter(item => item.visible === true);
    },
    /*  loadUnidades(){
       this.f = this.$store.state.faenas;
      
 
       let obj = this.f.faenas.filter(item => item.id === this.idFaena);
       if (Array.isArray(obj[0].unidades)) {
         //this.objJaulas = obj[0].unidades.filter(unidad => unidad.natural !== 'S');
 
         this.objJaulas =  obj[0].unidades.map( unidad=>{
             if (unidad.natural !== 'S'){
              
                  return unidad;
                
             }
         })
 
         this.dataLoaded = true;  
        } 
     }, */
    async getDivisionesApi() {
      //console.log("carga diviones", "id empresa");
      try {



        this.loadTree = true;
        this.loadMediciones = false;
        this.loadVistaplanta = false;







      } catch (err) {
        console.log(err);
      }
    },



  },
  computed: {
    ...mapGetters(["user", "token"]),

  },
  watch: {

    propIdFaena: function (value) {
      //this.dataLoaded = false;  
      //this.idFaena = value;
      // this.loadUnidades();
      console.log(value)
    },

    propObjJaulas: function (value) {

      //this.loadUnidades();
      this.filtraVisibles(value);

      this.dataLoaded = true;
    },
    storeJaulas: function (value) {
      alert('detecta cambios')
      console.log(value)
    }
  }

};
</script>
