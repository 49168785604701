<template>
  <div>


    <div class="md:flex md:items-center md:justify-between bg-white">
      <div class="min-w-0 flex-1 text-left pl-8">
        <h1 class="mt-3 text-1xl font-extrabold tracking-tight text-cyan-800">
          {{ nombreMedicion }} {{ nombreIdTipoMedicion }}
        </h1>
      </div>

      <div class="mt-4 flex md:mt-0 md:ml-4">
{{ labelErr }}
        <span class="isolate inline-flex rounded-md shadow-sm p-3">
          <button type="button" @click="chgTipo(1)" :class="{
            'border-gray-500 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500':
              natural,
            '': !natural,
            'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10': true,
          }">
            Natural
          </button>

          <button type="button" @click="chgTipo(2)" :class="{
            'border-blue-600 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600':
              ozonizado,
            '': !ozonizado,
            'relative -ml-px inline-flex items-center rounded-r-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 ': true,
          }">
            Ozonizado
          </button>
        </span>
        <span class="isolate inline-flex rounded-md shadow-sm ml-12 p-3">
          <button  type="button" @click="loadMapa(propIdFaena)"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            Mapa
          </button>
          <button type="button" @click="showModalHelp()"
            class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            ?
          </button>
        </span>
      </div>
    </div>
    <div class="bg-white shadow-lg">

   <!--     {{ this.valorMinY0 }} - {{ this.valorMaxY0 }} -->
      <highcharts v-if="chartOptions.series" :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>
<script>
//import axios from "axios";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

//import stockInit from 'highcharts/es-modules/Stock'
import stockInit from "highcharts/modules/stock";



stockInit(Highcharts);
//const colors = Highcharts.getOptions().colors;
Highcharts.setOptions({
  chart: {},
  lang: {
    months: [
      "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago",
      "Sep", "Oct", "Nov", "Dic",
    ],
    weekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  },
});

export default {
  components: {
    highcharts: Chart,


  },
  emits: ["loadMapa"],
  props: [
    "seriesMediciones",
    "idMedicion",
    "nombreMedicion",
      "propIdFaena"
  ],

  data() {
    return {
      natural: false,
      ozonizado: false,
      idTipo: 2,
      nombreIdTipoMedicion: "",
      mediciones: [],
      showModal: false,
      Centro2: [],
      labelErr: '',
      minY: 0,
      maxY: 25,
      tieneMediciones: [false, false, false],
      valorMaxY0: [0, 0, 0],
      valorMinY0: [0, 0, 0],
      chartOptions:
      {
        chart: {
          type: 'spline'
        },
        tooltip: {
          backgroundColor: {
            linearGradient: [0, 0, 0, 60],
            stops: [
              [0, '#FFFFFF'],
              [1, '#E0E0E0']
            ]
          },
          borderWidth: 1,
          borderColor: '#AAA'
        },
        legend: {
          enabled: true,

          layout: "vertical",
          align: "right",
          verticalAlign: "top",
        },

        plotOptions: {
      
        },

        xAxis: {
          showLastLabel: true,
        },
        yAxis: {
          opposite: false,
          title: {
            text: 'mV',
          },
          formatter: function (val) {
            return val.toFixed(2);
          },
          min: 0,
          max: 25,


        },
        series: [0,0,0,0],

        navigator: {
          series: {
            lineWidth: 2,
            fillOpacity: 0.04,
            dataGrouping: {
              smoothed: false,
            },
          },
        },
        rangeSelector: {
          selected: 2,
          floating: true,
          y: 400,
        },
        title: {
          text: "",
        },


      },
    };
  },
  watch: {
    // seriesMediciones['',[ obj_faena_natural ],  [obj_faena_oxigenado]]->   [ { obj_faena } ] - > obj_faena.data[] -> array_datos_diarios 
    seriesMediciones: function (valor) {
        if(Array.isArray(valor)){
          this.fillSeries(valor);
        }else{
          console.log('seriesMediciones debe ser array')
        }
     

    },

    
  },

  methods: {
    handleSelect(event) {
      const selectedId = event.target.value; // Captura el ID seleccionado
      console.log("ID seleccionado:", selectedId);
      this.loadMapa(selectedId)
    },
    fillSeries(med){
      console.log('this.idTipo',this.idTipo)
      let nuevoValor = med[this.idTipo]
      if (nuevoValor==null){
        this.labelErr="no se pudieron leer datos";
        return false;
      }
      nuevoValor.forEach(obj => {
        
        
        let zonas = [];

        obj.data = obj.data.map(([timestamp, cantidad]) => {
          cantidad = cantidad == null ? 0 : cantidad;

          zonas.push({
            'value': timestamp,
            'medicion': cantidad
          });

          if (cantidad !== 0) {
            console.log('cantidad', cantidad);
            this.tieneMediciones[this.idTipo] = true;
            this.valorMaxY0[this.idTipo] <= cantidad ? (this.valorMaxY0[this.idTipo] = cantidad) : (this.valorMinY0[this.idTipo] = cantidad);
          }
          return cantidad === 0 ? [timestamp, null] : [timestamp, cantidad];
        })
       
      });
      if( this.tieneMediciones[this.idTipo] == false){
        this.chartOptions.yAxis.max = this.maxY;
        this.chartOptions.yAxis.min = 0;
        this.chartOptions = {
            ...this.chartOptions,  
            xaxis: { categories: [] },  
            yaxis: [
              {
                ...this.chartOptions.yaxis ,  

                max:  this.maxY,  
                min:  0,  
                
              } 
            ],
          
        };
      }else{
        let estimadoMin =  this.valorMinY0[this.idTipo] - 0.9;
        let nuevoValorMin =  estimadoMin - 2 < 0 ? 0 : estimadoMin -1;
        let nuevoValorMax =  this.valorMaxY0[this.idTipo] + 1;
        this.chartOptions.yAxis.max =nuevoValorMax;
        this.chartOptions.yAxis.min = nuevoValorMin;

        this.chartOptions = {
            ...this.chartOptions,  
            xaxis: { categories: [] },  
            yaxis: [
              {
                ...this.chartOptions.yaxis ,  

                max:  nuevoValorMax,  
                min:  nuevoValorMin,  
                
              } 
            ],
          
        };
      }
      this.chartOptions.series = nuevoValor;


      if (this.idTipo == 2) {

        this.natural = false;
        this.ozonizado = true;
      } else {
        this.natural = true;
        this.ozonizado = false;
      }
    },
    getRandomInt(min, max) {
      return Math.round(Math.random() * (max - min)) + min;
    },
    chgTipo(tipo) {
      let nuevoValor = this.seriesMediciones[tipo]
      nuevoValor.forEach(obj => {
        // Inicializar el array zones
        obj.data = obj.data.map(([timestamp, cantidad]) =>
          cantidad === 0 ? [timestamp, null] : [timestamp, cantidad]
        );
      });


    

      if (tipo == 1) {
        this.natural = true;
        this.ozonizado = false;
      } else {
        this.natural = false;
        this.ozonizado = true;
      }

      this.idTipo = tipo;
      this.nombreIdTipoMedicion = this.idTipo == "1" ? "Natural" : "Ozonizado";
    
      this.fillSeries(this.seriesMediciones);


    },
    showModalHelp() {
      console.log("show modal");
      this.showModal = true;
    },
    closeModalHelp() {
      console.log("show modal");
      this.showModal = false;
    },
    loadMapa(f) {
      console.log("load mapa ");
      this.$emit("loadMapa", f);
    },
  },

  mounted() {
    

    console.log("montando");
    this.natural = false;
    this.ozonizado = true;

    //  this.manipulaSeriesNatural()
  },
};



</script>
