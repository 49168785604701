<template>
  <div>
    
    <ModalMapaFaenas :showModalMapa="showModalMapa" :propIdFaena="this.idFaena" @close="closeModal"> </ModalMapaFaenas>
    <ModalLayoutJaulas :showModalLayout="showModalLayout" @close="closeModalLayout"> </ModalLayoutJaulas>
    <MenuAyuda  :showModalAyuda="showModalAyuda" @close="closeModalAyuda"></MenuAyuda>
    <ModalVisibilidad :objJaulas="objJaulas" :showModalVisibilidad="showModalVisibilidad" @close="closeModalVisibilidad"
    @handleVisibilidad="handleVisibilidad"></ModalVisibilidad>
    <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-1">
      <div class="container mx-auto  p-3">
        
        <div class="w-full text-left ml-0">
          <span class="text-1xl text-white font-bold text-black text-left"> 
            Live  Centro {{ nombreFaena}}</span>  <span class="text-1xl text-white font-bold text-black text-left">  {{ fechahoraActual }}</span></div>
 
        <div class="lg:flex">
          <div class="lg:w-2/3  pl-0">
            <div class="container">
              <div class="w-full">
                <table class=" mt-1 border-spacing-0  w-full ">
                  <tbody class="  bg-white ">
                    <tr>

                      <td class="">
                        <div
                          class="flex-shrink-0 p-2  flex items-center justify-center  bg-blue-700 text-white text-sm font-medium rounded-l-md">
                          Pureza O<sub>2</sub></div>
                      </td>
                      <td class="">
                        <a href="#" class="pl-2 text-sm  text-gray-900 hover:text-gray-600">{{ purezao2 }} %</a>
                      </td>
                      <td class="">
                        <div
                          class="flex-shrink-0  p-2  flex items-center justify-center  bg-blue-700 text-white text-sm font-medium rounded-l-md">
                          Flujo O<sub>2</sub></div>
                      </td>
                      <td class="">
                        <a href="#" class="text-sm pl-2">{{ flujoo2 }}</a>
                      </td>
                      <td class="">
                        <div
                          class="flex-shrink-0   p-2  flex items-center justify-center  bg-green-500 text-white text-sm font-medium rounded-l-md">
                          O<sub>2</sub>&nbsp; Natural </div>
                      </td>
                      <td class="">
                        <a href="#" class="text-sm pl-2"> {{ o2natural }} mg/l</a>
                      </td>
                      <td class="">
                        <div
                          class="flex-shrink-0  p-2  flex items-center justify-center  bg-green-500 text-white text-sm font-medium rounded-l-md">
                          Salinidad </div>
                      </td>
                      <td class="">
                        <a href="#" class="text-sm pl-2">{{ orp }} PSU</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="w-full  mt-2">
                <table class=" mt-5 border-spacing-0 m-0 w-full pr-3 ">
                  <tbody class="  w-full  border-spacing-0   ">
                    <tr>
                      <td class="whitespace-nowrap py-1 text-sm    text-gray-900    text-center">
                        Simbolos:
                      </td>

                      <td class="whitespace-nowrap py-1   text-sm   text-gray-900    text-right">
                        Activo
                      </td>
                      <td class="whitespace-nowrap  py-1 text-sm text-gray-700 text-left">
                        <span class='pl-1 green custom-shadow'></span>

                      </td>

                      <td class="whitespace-nowrap  py-1 pr-0 text-sm   text-gray-900      text-right">
                        Falla Sensor
                      </td>
                      <td class="whitespace-nowrap  py-1 text-sm text-gray-700">

                        <span class='pl-1 red custom-shadow'></span>
                      </td>

                      <td class="whitespace-nowrap py-1 pr-0 text-sm   text-gray-900  text-right">
                        Bajo O<sub>2</sub>
                      </td>
                      <td class="whitespace-nowrap  py-1 text-sm text-gray-700">

                        <span class='pl-1 yellow custom-shadow'></span>
                      </td>

                      <td class="whitespace-nowrap py-1 pr-0 text-sm   text-gray-900  text-right">
                        Alto ORP
                      </td>
                      <td class="whitespace-nowrap  py-1 text-sm text-gray-700">

                        <div
                          class="ml-2 rounded-full mb-1   flex items-center justify-center h-5 w-5 bg-orange-500 custom-shadow">
                          &nbsp;
                        </div>

                      </td>

                      <td class="whitespace-nowrap py-1 pr-0 text-sm   text-gray-900    text-right">
                        Inactivo
                      </td>
                      <td class="whitespace-nowrap  py-1 text-sm text-gray-700 ">



                        <div
                          class=" ml-2 rounded-full mb-1   flex items-center justify-center h-5 w-5 bg-gray-400 custom-shadow">
                          &nbsp;
                        </div>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="lg:w-1/3  pl-8 pr-4 text-center">



            <div class=" grid   sm:grid-cols-4   md:grid-cols-4  lg:grid-cols-4  m-0 p-0  ">
             
              <a href="#"
                    class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col" @click="loadModalVisibilidad(true)">
                      <div class="flex-shrink-0">
                        <div
                          class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-.034.096-.07.192-.106.287C20.268 16.057 16.478 19 12 19c-4.478 0-8.268-2.943-9.542-7-.036-.095-.072-.191-.106-.287z" />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-xs text-gray-900">Visibilidad</p>
                        </div>
                      </div>
                    </div>
                  </a>
             
              <a href="#"
                class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
                <div class="flex md:h-full lg:flex-col" @click="loadAyuda()">
                  <div class="flex-shrink-0">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-8 h-8">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                    <div>
                      <p class="text-xs text-gray-900" @click="loadAyuda(true)">Información</p>

                    </div>

                  </div>
                </div>
              </a>

              <btnMapa v-if="tieneCoordenadas" @emitMapa="loadMapa" />
              <btnMapaDeshabilitado v-if="tieneCoordenadas==false" />
              <a href="#"
                class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
                <div class="flex md:h-full lg:flex-col" @click="loadLayout()">
                  <div class="flex-shrink-0">
                    <div
                      class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                      <!-- Heroicon name: outline/cursor-arrow-rays -->
                      <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                          d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                      </svg>

                    </div>
                  </div>
                  <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                    <div>
                      <p class="text-xs  text-gray-900" @click="loadLayout()">Layout
                      </p>

                    </div>

                  </div>
                </div>
              </a>



            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="bg-white">


      <div class="flex flex-wrap">

        <div class="w-full md:w-4/4  lg:w-4/4 ">
          
          <div v-if="dataLoaded==false"  class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 mt-8"> Cargando unidades ... </div>
 
        
          <GraficosLiveCentros :idEmpresa="idEmpresa" :propIdFaena="propIdFaena" :propObjJaulas="objJaulas">
          </GraficosLiveCentros>
        </div>
      </div>


    </div>
  </div>
</template>
<style scoped>
footer {
  z-index: 5000;
  width: 900px;
  height: 86px;
  padding-top: 4px;
  padding-bottom: 6px;
}

header {
  padding-top: 2px;
  z-index: 5000;
  width: 300px;
  height: 200px;
  margin-left: 200px;

}
</style>

<script>

import GraficosLiveCentros from "./GraficosLiveCentros";
import ModalMapaFaenas   from "../resumen/modal/ModalMapaFaenas";
import ModalLayoutJaulas from "../resumen/modal/ModalLayoutJaulas";
import ModalVisibilidad  from "./modal/ModalVisibilidad";
import MenuAyuda from "./MenuAyuda"; 
import btnMapa from "./ui/btnMapa"
import btnMapaDeshabilitado from "./ui/btnMapaDeshabilitado"
import axios from "axios";

export default {
  name: "FaenasCliente",
  props: ["idEmpresa",   "propIdFaena", "nombreFaena", "numRam"],
  emits: ['chgFaena',  'handleDropFaena'],
  components: {
    GraficosLiveCentros, ModalLayoutJaulas, btnMapaDeshabilitado,
    ModalMapaFaenas, MenuAyuda,ModalVisibilidad,btnMapa
  },
  mounted() {
    console.log('monta VistaCentros')
    this.purezao2 = 'Sin datos'
    this.flujoo2 = 'Sin Datos'
    this.idFaena = this.propIdFaena;
    this.loadUnidades()
    this.loadUnidades_natural()
    this.fechahora();
    this.getCoordenadas();
    this.polldata();
  },
  data() {
    return {
      o2natural: 0,
      salinidad: 0,
      orp: 0,
      purezao2: 0,
      flujoo2: 0,
      seriesLoaded: false,
      dataLoaded: false,
      datos_mensuales: [],
      faenas: [{}],
      cards: [{}],
      objFaenas: [{}],
      seriesFaenas: [],
      numRamdom: this.numRam,
      showModalMapa: false,
      showModalLayout: false,
      showModalAyuda: false,
      unidadTipoPlanta: {},
      idFaena: this.propIdFaena,
      objJaulas: [],
      objJaulasVisibles: [],
      showModalVisibilidad: false,
      fechahoraActual:'',
      tieneCoordenadas: false,
    };
  },
  methods: {
    getCoordenadas(){
       let objStoreFaenas   = this.$store.state.faenas;
       this.objFaena = objStoreFaenas.faenas.filter(faena => faena.id == this.propIdFaena);
       if (Array.isArray(this.objFaena)  && this.objFaena.length > 0){
        
       let longitud =   this.objFaena[0]["longitud"];
       let latitud  =  this.objFaena[0]["latitud"];
       if(longitud && latitud){
          this.tieneCoordenadas=true;
       }
       }
    },
    loadModalVisibilidad(valor) {
      console.log("modifica estado modal");
      this.showModalVisibilidad = valor;
    },
    handleVisibilidad() {
       // this.$emit("handleDropFaena");
       console.log('handle visibilidad jaula')
       this.loadUnidades() 
       this.$emit("handleDropFaena");
    },
    getRnd(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(2)
    },
    loadMapa() {
      console.log('LOAD MAPA')
      this.showModalMapa = true
    },
    loadLayout() {
      console.log('LOAD LAYOUT')
      this.showModalLayout = true
    },
    loadAyuda() {
      console.log('LOAD AYUDA')
      this.showModalAyuda = true
    },
    closeModal() {
      this.showModalMapa = false
    },
    closeModalLayout() {
      this.showModalLayout = false
    },
    closeModalAyuda() {
      this.showModalAyuda = false
    },
    closeModalVisibilidad() {
      this.showModalVisibilidad = false;
    },
    polldata() {
      this.polling = setInterval(() => {
         // this.getdataendpoint_endesuso() ;
         this.fechahora()
      }, 1);
    },
    fechahora(){
     
      let objFecha = new Date();

      const y = objFecha.getFullYear();
      const m = String(objFecha.getMonth() + 1).padStart(2, '0'); // Los meses   son 0-11
      const d = String(objFecha.getDate()).padStart(2, '0');

      const h = String(objFecha.getHours()).padStart(2, '0');
      const min = String(objFecha.getMinutes()).padStart(2, '0');
      const s = String(objFecha.getSeconds()).padStart(2, '0');

      this.fechahoraActual = `${d}-${m}-${y} ${h}:${min}:${s}`;


    },
    async getdataendpoint_endesuso() {

      console.log('getdataendpoint')


      var objFechaHora = new Date();

      //const fechaHoraStr = "2024-07-11 00:00:00";      

      let objFecha = new Date();

      const y = objFecha.getFullYear();
      const m = String(objFecha.getMonth() + 1).padStart(2, '0'); // Los meses   son 0-11
      const d = String(objFecha.getDate()).padStart(2, '0');

      const h = String(objFecha.getHours()).padStart(2, '0');
      const min = String(objFecha.getMinutes()).padStart(2, '0');
      const s = String(objFecha.getSeconds()).padStart(2, '0');

      const fechaHoraStr = `${y}-${m}-${d} ${h}:${min}:${s}`;

      const [fecha, hora] = fechaHoraStr.split(' ');
      const [year, month, day] = fecha.split('-').map(Number);
      const [hours, minutes, seconds] = hora.split(':').map(Number);
      objFechaHora = new Date(year, month - 1, day, hours, minutes, seconds);


      console.log('objFechaHora', objFechaHora);
      var nuevoFormato = {
        year: objFechaHora.getFullYear(),
        month: ('0' + (objFechaHora.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHora.getDate()).slice(-2),
        hours: ('0' + objFechaHora.getHours()).slice(-2),
        minutes: ('0' + objFechaHora.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHora.getSeconds()).slice(-2)
      };

      this.fechaFinalParche = `${nuevoFormato.day}-${nuevoFormato.month}`;
      this.horaFinalParche = `${nuevoFormato.hours}:${nuevoFormato.minutes}`;
      const fFinal = `${nuevoFormato.year}-${nuevoFormato.month}-${nuevoFormato.day} ${nuevoFormato.hours}:${nuevoFormato.minutes}:${nuevoFormato.seconds}`;


      var objFechaHoraMedio = new Date();
      objFechaHoraMedio.setTime(objFechaHora.getTime() - 43200000);
      nuevoFormato = {
        year: objFechaHoraMedio.getFullYear(),
        month: ('0' + (objFechaHoraMedio.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHoraMedio.getDate()).slice(-2),
        hours: ('0' + objFechaHoraMedio.getHours()).slice(-2),
        minutes: ('0' + objFechaHoraMedio.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHoraMedio.getSeconds()).slice(-2)
      };

      this.fechaMitadParche = `${nuevoFormato.day}-${nuevoFormato.month}`;
      this.horaMitadParche = `${nuevoFormato.hours}:${nuevoFormato.minutes}`;



      objFechaHora.setTime(objFechaHoraMedio - 43200000);
      nuevoFormato = {
        year: objFechaHora.getFullYear(),
        month: ('0' + (objFechaHora.getMonth() + 1)).slice(-2),
        day: ('0' + objFechaHora.getDate()).slice(-2),
        hours: ('0' + objFechaHora.getHours()).slice(-2),
        minutes: ('0' + objFechaHora.getMinutes()).slice(-2),
        seconds: ('0' + objFechaHora.getSeconds()).slice(-2)
      };

      this.fechaInicialParche = `${nuevoFormato.day}-${nuevoFormato.month}`;
      this.horaInicialParche = `${nuevoFormato.hours}:${nuevoFormato.minutes}`;
      const fInicial = `${nuevoFormato.year}-${nuevoFormato.month}-${nuevoFormato.day} ${nuevoFormato.hours}:${nuevoFormato.minutes}:${nuevoFormato.seconds}`;




      if (!this.unidadTipoPlanta.id) {
        console.log('getdataendpoint','false')
        return false;
      }
      if (!this.unidadTipoPlanta.sensores) {
        return false;
      }

      const apiKey = this.$store.state.login.tokenLogin;

      const data = {
        idUnit: this.unidadTipoPlanta.id,
        tsi: `${fInicial}`,
        tsf: `${fFinal}`
      };


      const fetchData = async () => {
        try {
          const url = "/api/mon/getUnitData";
          const response = await axios.post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${apiKey}`
            }
          });


          const datos = response.data.payload.sensors;




          let temperaturaValues = [];
          let oxigenoValues = [];
          let salinidadValues = [];
          let orpValues = [];
          // ¿ SIEMPRE ES UN SENSOR POR JAULA ?
          this.idSensorAFiltrar1 = this.unidadTipoPlanta.sensores[0]; // viene de props

          datos.filter(sensor => sensor.id === this.idSensorAFiltrar1)
            .forEach(sensor => {
              sensor.params.forEach(param => {
                let id = param.id;
                let updatedData = [...param.values].splice(-24);
                updatedData.forEach(valor => {
                  // {'value':valor.value,'ts':valor.ts}

                  switch (id) {
                    case 1: temperaturaValues.push({ ...valor }); break;
                    case 2: salinidadValues.push({ ...valor }); break;
                    case 4: orpValues.push({ ...valor }); break;
                    case 5: oxigenoValues.push({ ...valor }); break;


                  }



                  this.result_mediciones_haydatos = true;
                });
              });
            });


          // Procesar oxigenoValues en una promesa
          return new Promise((resolve, reject) => {
            try {


              let categorias = [];



              oxigenoValues.forEach((mobile) => {


                let med = mobile["value"];

                this.o2natural = med.toFixed(2);
                console.log("natural", this.o2natural, "<----")
              });

              salinidadValues.forEach((mobile) => {
                let med = mobile["value"];

                this.salinidad = med.toFixed(2);
                console.log("salinidad", this.salinidad, "<----")
              });


              orpValues.forEach((mobile) => {
                let med = mobile["value"];

                this.orp = med.toFixed(2);
                console.log("orp", this.orp, "<----")
              });



              resolve({
                temperaturaValues,
                oxigenoValues,

                categorias,
                ultfecha: this.ultfecha,
                firstdate: this.firstdate,
                firsthour: this.firsthour,
                middledate: this.middledate,
                middlehour: this.middlehour,
                lastdate: this.lastdate,
                lasthour: this.lasthour,
                ultmgl: this.ultmgl
              });
            } catch (error) {
              reject(error);
            }
          });
        } catch (error) {
          console.error(error);
        }
      };

      fetchData().then(() => {

        this.chartOptions = {
          xaxis: { categories: [] }, //categorias
        };

        this.result_mediciones = true;

      }).catch(error => {
        console.error("Error processing data:", error);
      });






    },
    loadUnidades() 
    {
      let  storeVacio = true;
      console.log('storeJaulas',this.$store.state.jaulas.Jaulas)
      if (this.$store.state.jaulas.Jaulas.length > 0){
                this.storeJaulas = this.$store.state.jaulas;    
                storeVacio = false;   
      } 

      this.f = this.$store.state.faenas;
      let obj = this.f.faenas.filter(item => item.id === this.idFaena);
      if (Array.isArray(obj[0].unidades)) 
      {
        this.objJaulas = obj[0].unidades.filter(unidad => unidad.natural !== true);
        if(Array.isArray(this.objJaulas))
        {
        
          this.objJaulasVisibles = this.objJaulas.map( jaula => {
             
                if (!storeVacio) 
                {
                  let arrayJaula  = this.storeJaulas.Jaulas.filter(store => store.id == jaula.id  && store.visible == false);
                  console.log('revisa:',jaula.id , arrayJaula.length) 
                  if (arrayJaula.length>0){
                     
                    jaula.visible=  false;
                 
                  }else{
                    jaula.visible=  true;
                  }
              
               

              }else{
                // no existe jaula en el store visibilidad jaulas
                jaula.visible=true;
                
                // this.$store.dispatch("jaulaAdd", jaula);
                 return jaula;
              }
              

          });
        

        }

        this.dataLoaded = true;
      }


    },
    loadUnidades_natural() {
      this.o2natural = '--';
      this.orp = '--';
      let objJaulas= []
      let f = this.$store.state.faenas;
      let obj = f.faenas.filter(item => item.id === this.idFaena);
      if (Array.isArray(obj[0].unidades)) {
        objJaulas = obj[0].unidades.filter(unidad => unidad.natural == 'S');

    
        if (objJaulas && typeof objJaulas === 'object') {
       

          const jaula = objJaulas[0];
          if (jaula && 'id' in jaula) {
            this.unidadTipoPlanta.id = jaula.id;
          }
          if (jaula && 'sensores' in jaula) {
            this.unidadTipoPlanta.sensores = jaula.sensores;
          }

        }
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  watch: {

    propIdFaena: function (value) {
      this.dataLoaded= false;
      this.idFaena = value;
      this.loadUnidades();
      this.loadUnidades_natural();
    }
  }


};
</script>
