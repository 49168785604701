<template>
  <div>
    <ModalHelp :showModal="showModal" :texto="nombreIdTipoMedicion" @close="closeModalHelp">
    </ModalHelp>

    <div class="md:flex md:items-center md:justify-between bg-white">
      <div class="min-w-0 flex-1 text-left pl-8">
        <h1 class="mt-3 text-1xl font-extrabold tracking-tight text-cyan-800">
          {{ nombreMedicion }} {{ nombreIdTipoMedicion }}
        </h1>
      </div>
      <div class="min-w-0 flex-1 text-left pl-0">
        <AlertDummy v-show="glosaDataTypeNatural.faenas.length>0" :glosa="glosaDataTypeNatural.glosa" :glosa2="glosaDataTypeNatural.faenas"></AlertDummy>
      </div>
      <div class="min-w-0 flex-1 text-left pl-0">
    
        <AlertDummy v-show="glosaDataTypeOxigenado.faenas.length>0" :glosa="glosaDataTypeOxigenado.glosa"  :glosa2="glosaDataTypeOxigenado.faenas"></AlertDummy>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        {{ labelErr }}
        <span class="isolate inline-flex rounded-md shadow-sm p-3">
          <button type="button" @click="chgTipo(1)" :class="{
            'border-gray-500 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500':
              natural,
            '': !natural,
            'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10': true,
          }">
            Natural
          </button>

          <button type="button" @click="chgTipo(2)" :class="{
            'border-blue-600 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600':
              oxigenado,
            '': !oxigenado,
            'relative -ml-px inline-flex items-center rounded-r-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 ': true,
          }">
            Oxigenado
          </button>
        </span>
  
        <span class="isolate inline-flex rounded-md shadow-sm ml-12 p-3">
      
          <select id="faenas" name="faenas"
           class = "block  rounded-none w-full   border-gray-300 bg-white py-2 px-4 shadow-sm     sm:text-sm"
           @change="handleSelect">
          <option value="0">Mapas</option>
          <option v-for="faena in this.$store.state.faenas.faenas" :key="faena.id" :value="faena.id">{{ faena.nombre }}</option>
          </select>
          <button type="button" @click="showModalHelp()"
            class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
            ?
          </button>
        </span>
      </div>
    </div>
    <div class="bg-white shadow-lg">
      <textarea id="adjustable-textarea" row="100" class="w-full  p-2 border border-gray-300 rounded
         text-xs focus:outline-none focus:ring-2 focus:ring-blue-500 text-blue hidden " v-model="this.fs"></textarea>

      <!--   {{ this.valorMinY0  }} - {{ this.valorMaxY0  }}-->
      <highcharts :constructor-type="'stockChart'" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>
<script>
//import axios from "axios";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

//import stockInit from 'highcharts/es-modules/Stock'
import stockInit from "highcharts/modules/stock";
import ModalHelp from "./modal/ModalHelp";
import AlertDummy from "../AlertDummy.vue";


stockInit(Highcharts);
//const colors = Highcharts.getOptions().colors;
Highcharts.setOptions({
  chart: {

  },
  lang: {
    months: [
      "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago",
      "Sep", "Oct", "Nov", "Dic",
    ],
    weekdays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  },
});

export default {
  components: {
    highcharts: Chart,
    ModalHelp,
    AlertDummy
  },
  emits: ["loadMapa"],
  props: [
    "seriesMediciones",
    "idMedicion",
    "nombreMedicion",
    "tituloY",
    "glosaDataTypeOxigenado",
    "glosaDataTypeNatural"
  ],

  data() {
    return {
      faenas:{},
      fs: '',
      labelErr: '',
      natural: false,
      oxigenado: false,
      idTipo: 2,
      nombreIdTipoMedicion: "",
      mediciones: [],
      showModal: false,
      Centro2: [],
      minY: 0,
      maxY: 25,
      tieneMediciones: [false, false, false],
      valorMaxY0: [0, 0, 0],
      valorMinY0: [0, 0, 0],
      txtOxigenado:'',
      txtNatural:'',
      chartOptions:
      {
        chart: {
          type: 'spline'
        },
        tooltip: {
          backgroundColor: {
            linearGradient: [0, 0, 0, 60],
            stops: [
              [0, '#FFFFFF'],
              [1, '#E0E0E0']
            ]
          },
          borderWidth: 1,
          borderColor: '#AAA'
        },
        legend: {
          enabled: true,

          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          spline: {
            marker: {
              symbol: 'square'
            },

          },


          series: {
            showInNavigator: true,



          },
        },

        xAxis: {
          showLastLabel: true,
        },
        yAxis: {
          opposite: false,
          title: {
            text: this.tituloY,
          },
          formatter: function (val) {
            return val.toFixed(2);
          },


        },
        series: [
          {
            name: '', // Nombre de la primera serie
            data: [], // Datos para la primera serie
            yAxis: 0, // Indica que esta serie usa el primer eje Y (el izquierdo),
            zones: [],
            zoneAxis: 'x',
            marker: {
              enabled: false,
            }
          }
        ],

        navigator: {
          series: {
            lineWidth: 2,
            fillOpacity: 0.04,

            dataGrouping: {
              smoothed: false,
            },
          },
        },
        rangeSelector: {
          selected: 2,
          floating: true,
          y: 400,
        },
        title: {
          text: "",
        },


      },
    };
  },
  


 

  methods: {
    handleSelect(event) {
      const selectedId = event.target.value; // Captura el ID seleccionado
      console.log("ID seleccionado:", selectedId);
      this.loadMapa(selectedId)
    },
    fillSeries(med) {
      console.log('this.idTipo', this.idTipo)
      this.chartOptions.series = [];
      let nuevoValor = med[this.idTipo];
      if (nuevoValor == null) {
        this.labelErr = "no se pudieron leer datos";
        return false;
      }
      nuevoValor.forEach(obj => {

        let zones_oxigeno = []

        let ultimo_injection_tag = '';
        let index = 0;
        obj.data = obj.data.map(([timestamp, cantidad, injection]) => {

          this.fs= `${timestamp}, ${cantidad}, ${injection} -`

          if (index == 0) {

            ultimo_injection_tag = injection

          } else {

            if (injection !== ultimo_injection_tag) {

              zones_oxigeno.push({
                tag: injection,
                value: timestamp,
                color: ultimo_injection_tag == false && injection == true ? 'cyan' : '',
              })

            } else {
              zones_oxigeno.push({
                tag: injection,
                value: timestamp,
                color: ultimo_injection_tag == true ? 'cyan' : '',
              })


            }

            ultimo_injection_tag = injection;

          }


          cantidad = cantidad == null ? 0 : cantidad;
          if (cantidad > 0) this.tieneMediciones[this.idTipo] = true;

          
          if (cantidad>0)
            this.valorMaxY0[this.idTipo] <= cantidad ? (this.valorMaxY0[this.idTipo] = cantidad) : (this.valorMinY0[this.idTipo] = cantidad);

         

          

          index++;


          return cantidad === 0 ? [timestamp, null, injection] : [timestamp, cantidad, injection];
        })

        obj.zones = zones_oxigeno;
        obj.zoneAxis = 'x';
        obj.yAxis = 0;

      });




      this.chartOptions.series = nuevoValor;

      if (this.tieneMediciones[this.idTipo] == false) {
        this.chartOptions.yAxis.max = 25;
        this.chartOptions.yAxis.min = 0;
      } else {
        let estimadoMin = this.valorMinY0[this.idTipo] - 0.9;
        let nuevoValorMin = estimadoMin - 4 < 0 ? 0 : estimadoMin - 1;
        let nuevoValorMax = this.valorMaxY0[this.idTipo] + 0.1;
        this.chartOptions.yAxis.max = nuevoValorMax;
        this.chartOptions.yAxis.min = nuevoValorMin;

        this.chartOptions = {
          ...this.chartOptions,
          xaxis: { categories: [] },
          yaxis: [
            {
              ...this.chartOptions.yaxis,

              max: nuevoValorMax,
              min: nuevoValorMin,

            }
          ],

        };
      }
    



      if (this.idTipo == 2) {

        this.natural = false;
        this.oxigenado = true;

      } else {
        this.natural = true;
        this.oxigenado = false;



      }

    },
    getRandomInt(min, max) {
      return Math.round(Math.random() * (max - min)) + min;
    },
    chgTipo(tipo) {
   


      if (tipo == 1) {
        this.natural = true;
        this.oxigenado = false;
        this.nombreIdTipoMedicion = "Oxigenado";
      } else {
        this.natural = false;
        this.oxigenado = true;
        this.nombreIdTipoMedicion = "Natural";
      }

      this.idTipo = tipo;
      this.nombreIdTipoMedicion = this.idTipo == "1" ? "Natural" : "Oxigenado";
      this.fillSeries(this.seriesMediciones);
    },
    showModalHelp() {
      console.log("show modal");
      this.showModal = true;
    },
    closeModalHelp() {
      console.log("show modal");
      this.showModal = false;
    },
    loadMapa(f) {
      console.log("load mapa ");
      this.$emit("loadMapa", f);
    },
    ajusteY() {


    },
  },

  mounted() {


    this.natural = false;
    this.oxigenado = true;
    

  },
  watch: {
    // seriesMediciones['',[ obj_faena_natural ],  [obj_faena_oxigenado]]->   [ { obj_faena } ] - > obj_faena.data[] -> array_datos_diarios 
    seriesMediciones: function (valor) {
      
      if (Array.isArray(valor)) {
        this.fillSeries(valor);
      } else {
        console.error("seriesMediciones debe ser un arreglo. Valor proporcionado:", valor);

      }


    },
    
 
    },
};



</script>
